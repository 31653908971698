<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Tambah Remunerasi'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'remunerations'"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import Form from '@/component/masters/remunerations/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
     form: {
        employee_id: "",
        remuneration_type_id: "",
        ammount: "",
        is_tax:null,
      },
    };
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Remunerasi", route: "" },
      { title: "Daftar Remunerasi", route: "/masters/remunerations/list" },
      { title: "Tambah" },
    ])
  },

}

</script>